import React, { useState } from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import { Col, Row } from "react-bootstrap"
import Img from "gatsby-image"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const Project = () => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: {pieceDirectory: {eq: "phase of something in between"}, pieceType: {eq : "installation"}}) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            full: fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  const lightboxImages = data.images.nodes.map(n => n.childImageSharp.full.src)
  const [index, setIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const prevIndex = index - (1 % lightboxImages.length)
  const nextIndex = (index + lightboxImages.length + 1) % lightboxImages.length
  return (
    <Layout>
      {isOpen ?
        <Lightbox
          mainSrc={lightboxImages[index]}
          nextSrc={lightboxImages[nextIndex]}
          prevSrc={lightboxImages[prevIndex]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setIndex(prevIndex)}
          onMoveNextRequest={() => setIndex(nextIndex)}
        />
        : ""}
      <SEO title="phase of something in between" />
      <Row>
        {data.images.nodes.map((i, thumbIndex) =>
          <>
            {thumbIndex === 0 ?
              <Col lg={2} className="py-2">
                <b>Title : </b>Phase of Something in Between<br />
                <b>Year : </b>2019<br />
                <b>Material : </b>clay, paper, acryplic paint and cardboard<br />
                <b>Size : </b>320 x 210 x 200 cm<br />
              </Col> : ""
            }
            <Col lg={{ span: 10, offset: (index === 0 ? 0 : 1) }} className="py-2" >
              <a onClick={() => {
                setIndex(thumbIndex)
                setIsOpen(true)
              }}>

                <Img fluid={i.childImageSharp.fluid}
                  style={{ "maxHeight": "calc(100vh - 4rem)" }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </a>
            </Col>
          </>
        )
        }
      </Row>
    </Layout>
  )
}

export default Project
